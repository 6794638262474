<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl py-0 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-overlay
                        :value="loading.items"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :fixed-header="scrollingList"
                        :footer-props="{
                            itemsPerPageOptions: [50,100,200,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                        :headers="headers"
                        :height="tableHeight"
                        :items="Sales"
                        :search="searchTerm"
                        :options.sync="tableOptions"
                        calculate-widths
                        class="mt-0 appic-table-light specification-table"
                        dense
                        id="appicReportTable"
                        item-key="Sale.key"
                    >
                        <template v-slot:top>
                            <v-row no-gutters class="pt-2">
                                <v-col cols="4" class="d-flex flex-row">
                                    <v-text-field
                                        :label="$t('message.filterResults') + ' (' + $t('message.pressEnterToSearch') + ')'"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        class="pt-0 ml-2"
                                        hide-details="auto"
                                        prepend-inner-icon="mdi-filter-outline"
                                        @change="searchTerm = $event"
                                    />
<!--                                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults" v-if="false">{{ $t('message.filter') }}</v-btn>-->
                                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-switch
                                    :label="$t('message.scrollingList')"
                                    class="font-weight-bold"
                                    dense
                                    hide-details="auto"
                                    v-model="scrollingList"
                                ></v-switch>
                            </v-row>
                            <v-row no-gutters class="pb-2 pt-0">
                                <v-col cols="12" class="d-flex flex-row">
                                    <div class="font-sm">
                                        <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.open') }}
                                    </div>
                                    <div class="font-sm ml-3">
                                        <v-icon small color="orange lighten-3">fiber_manual_record</v-icon>{{ $t('message.completed') }}
                                    </div>
                                    <div class="font-sm ml-3">
                                        <v-icon small color="pink lighten-3">fiber_manual_record</v-icon>{{ $t('message.unsigned') }}
                                    </div>
                                    <div class="font-sm ml-3">
                                        <v-icon small color="blue darken-4">fiber_manual_record</v-icon>{{ $t('message.poUnsigned') }}
                                    </div>
                                    <div class="font-sm ml-3">
                                        <v-icon small color="yellow lighten-1">fiber_manual_record</v-icon>{{ $t('message.whEprPoUnsigned') }}
                                    </div>
                                    <div class="font-sm ml-3">
                                        <v-icon small color="purple lighten-1">fiber_manual_record</v-icon>{{ $t('message.cancelled') }}
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.Sale.type="{item}">
                            <span class="ml-1">{{ item.Sale.type.toUpperCase() }}</span>
                        </template>
                        <template v-slot:item.Sale.salestatus_id="{ item }">
                            <div class="text-center">
                                <v-icon small color="green lighten-1" v-if="item.Sale.salestatus_id == 1">fiber_manual_record</v-icon>
                                <v-icon small color="orange lighten-3" v-if="item.Sale.salestatus_id == 2">fiber_manual_record</v-icon>
                                <v-icon small color="purple lighten-1" v-if="item.Sale.salestatus_id == 10">fiber_manual_record</v-icon>
                                <v-icon small color="red" v-if="[1,2,10].includes(item.Sale.salestatus_id) == false">fiber_manual_record</v-icon>
                                <v-icon small color="pink lighten-3" v-if="getStatus(item) == 'contractUnsigned'">fiber_manual_record</v-icon>
                                <v-icon small color="blue darken-4" v-if="getStatus(item) == 'poUnsigned'">fiber_manual_record</v-icon>
                                <v-icon small color="yellow lighten-3" v-if="getStatus(item) == 'whPoUnsigned'">fiber_manual_record</v-icon>
                            </div>
                        </template>
                        <template v-slot:item.Sale.title="{item}">
                            <span class="font-weight-bold" v-if="!['WH','EPR'].includes(item.Sale.type)"  v-highlight="highlightTextObject">
                                {{ item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title }}
                            </span>
                            <span class="font-weight-bold" v-if="item.Sale.type == 'WH'"  v-highlight="highlightTextObject">
                                {{item.Sale.title}}
                            </span>
                            <span class="font-weight-bold" v-if="item.Sale.type == 'EPR'"  v-highlight="highlightTextObject">
                                {{item.Sale.title + (item.Sale.Contract.title != null ? ' / ' + item.Sale.Contract.title : '')}}
                            </span>
                        </template>
                        <template v-slot:item.Sale.salesdate="{item}">
                            {{ formatDate(item.Sale.salesdate) }}
                        </template>
                        <template v-slot:item.Sale.Customer.title="{item}">
                            <span v-highlight="highlightTextObject" v-if="item.Sale.type != 'EPR'">{{ item.Sale.Customer.title }}</span>
                            <span v-highlight="highlightTextObject" v-if="item.Sale.type == 'EPR'">{{ item.Sale.Customer.title + (item.Sale.Contract.title != null ? ' / ' + item.Sale.Contract.customer : '') }}</span>
                        </template>
                        <template v-slot:item.Sale.SalesContact.abbreviation="{item}">
                            <span v-if="[1,2,5,6,99].includes(item.Sale.salestype_id)">{{ item.Sale.SalesContact.abbreviation }}</span>
                            <span v-else>{{ $t('message.na') }}</span>
                        </template>
                        <template v-slot:item.Sale.Supplier.title="{item}">
                            <span v-highlight="highlightTextObject" v-if="[2,3,6,100].includes(item.Sale.salestype_id)">{{ item.Sale.Supplier.title }}</span>
                            <span v-highlight="highlightTextObject" v-if="[1,5].includes(item.Sale.salestype_id)">{{ $t('message.app') }}</span>
                        </template>
                        <template v-slot:item.Sale.po="{item}">
                            <span v-if="[1,5].includes(item.Sale.salestype_id)">
                                <v-icon small color="yellow lighten-1" v-if="item.Sale.posigned != 1 && false">fiber_manual_record</v-icon>
                                {{ item.Sale.Supplier.code }}
                            </span>
                            <span v-if="[2].includes(item.Sale.salestype_id)">{{ $t('message.ok').toUpperCase() }}</span>
                            <span v-if="[3,6,100].includes(item.Sale.salestype_id)">
                                <v-icon small color="yellow lighten-1" v-if="item.Sale.posigned != 1">fiber_manual_record</v-icon>
                                {{ $t('message.ok').toUpperCase() }}
                            </span>
                        </template>
                        <template v-slot:item.Sale.Species.title="{item}">
                            <span v-highlight="highlightTextObject">{{ item.Sale.Species.title }}</span>
                        </template>
                        <template v-slot:item.Sale.Grade.title="{item}">
                            <span v-highlight="highlightTextObject">{{ item.Sale.Grade.title }}</span>
                        </template>
                        <template v-slot:item.Sale.Size.title="{item}">
                            <span class="truncate">{{ item.Sale.Size.title }}</span>
                        </template>s
                        <template v-slot:item.Sale.containers="{item}">
                            <span class="text-no-wrap">{{ item.Sale.containers != '' ? item.Sale.containers : $t('message.na')  }}</span>
                        </template>
                        <template v-slot:item.Sale.cost="{item}">
                            <span class="text-no-wrap">{{ item.Sale.Contract.currency + " " + formatThisNumber(item.Sale.cost, '0,0') }}</span>
                        </template>
                        <template v-slot:item.Sale.value="{item}">
                            <span class="text-no-wrap">{{ item.Sale.Contract.currency + " " + formatThisNumber(item.Sale.value, '0,0') }}</span>
                        </template>
                        <template v-slot:item.Sale.shipweekdate="{item}">
                            <span class="text-no-wrap">{{ formatDate(item.Sale.shipweekdate) }}</span>
                        </template>
                        <template v-slot:item.Sale.shipment_status="{ item }">
                            <span :class="isUpdated('ShipmentStatus')" v-if="item.Sale.type != 'wh'">
                                {{ item.Sale.shipment_status }}
                            </span>
                            <span v-if="item.Sale.type == 'wh'">{{ $t('message.na') }}</span>
                        </template>

                        <template v-slot:item.Sale.etsdate="{item}">
                            <span class="text-no-wrap">{{ formatDate(item.Sale.etsdate) }}</span>
                        </template>
                        <template v-slot:item.Sale.etddate="{item}">
                            <span :class="'text-no-wrap' + isUpdated('ETD')">{{ formatDate(item.Sale.etddate) }}</span>
                        </template>
                        <template v-slot:item.Sale.etadate="{item}">
                            <span :class="'text-no-wrap' + isUpdated('ETA')">{{ formatDate(item.Sale.etadate) }}</span>
                        </template>
                        <template v-slot:item.Sale.internal_remarks="{item}">
                            <span :class="isUpdated('InternalRemark')">{{ item.Sale.internal_remarks }}</span>
                        </template>
                        <template v-slot:item.Sale.Paymentterm.title="{item}">
                            <span v-if="[3,6].includes(item.Sale.salestype_id) == false">{{ item.Sale.Paymentterm.title }}</span>
                        </template>
                        <template v-slot:item.Sale.Invoice.title="{item}">
                            <span v-highlight="highlightTextObject">{{ item.Sale.Invoice.title }}</span>
                        </template>
                        <template v-slot:item.Sale.Debitnote.title="{item}">
                            <span v-highlight="highlightTextObject">{{ item.Sale.Debitnote.title }}</span>
                        </template>
                        <template v-slot:item.Sale.margin="{item}">
                            <span class="text-no-wrap float-right">{{ item.Sale.Contract.currency + " " + formatThisNumber(item.Sale.material_margin, '0,0') }}</span>
                        </template>
                        <template v-slot:item.Sale.margin_pct="{item}">
                            <span class="text-no-wrap float-right" v-if="[1,2,5,99].includes(item.Sale.salestype_id)">{{ formatThisNumber(item.Sale.material_margin_pct,'0.0') }}</span>
                            <span class="text-no-wrap float-right" v-if="[3,6].includes(item.Sale.salestype_id)">{{ $t('message.na') }}</span>
                        </template>
                        <template v-slot:item.Sale.document_id="{ item }">
                            <v-menu>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>{{ $t('message.moreActions') }}</span>
                                    </v-tooltip>
                                </template>
                                <v-list dense>
                                    <v-list-item class="font-sm" @click="updateContract(item.Sale.document_id)" v-if="['DS','CS','PO','SS','PO-EXP'].includes(item.Sale.type) && $can('update','Contract')"><v-icon color="black" small class="mr-1">edit</v-icon>{{ $t('message.updateContract') }}</v-list-item>

                                    <!--added 2021-08-23-->
                                    <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Sale.Purchaseorder.id)" v-if="['DS','CS','PO','SS','PO-EXP'].includes(item.Sale.type) && [1,2].includes(item.Sale.salestatus_id) && item.Sale.Purchaseorder.id != null && $can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>
                                    <!---->

                                    <v-list-item class="font-sm" @click="updateShipment(item.Sale.document_id)" v-if="['DS','CS','PO','SS','PO-EXP'].includes(item.Sale.type) && [1,2].includes(item.Sale.salestatus_id) && $can('update','Shipment')"><v-icon color="black" small class="mr-1">edit</v-icon>{{ $t('message.updateShipment') }}</v-list-item>

                                    <!--added 2021-08-06-->
                                    <v-list-item class="font-sm" @click="updateShippingInstruction(item.Sale.Sda.id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('update','Shipment') && item.Sale.Sda.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateShippingInstruction') }}</v-list-item>
                                    <!---->

                                    <!--added 2021-10-15-->
                                    <v-list-item class="font-sm" @click="updateLca(item.Sale.Lca.id)" v-if="[1,2,5].includes(item.Sale.salestype_id) && item.Sale.Lca.id != null && $can('update','Shipment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateLca') }}</v-list-item>
                                    <!---->

                                    <!--added 2021-08-06-->
                                    <v-list-item class="font-sm" @click="updatePackingList(item.Sale.Pldo.id)" v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && $can('update','Shipment') && item.Sale.Pldo.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="updateInvoice(item.Sale.Invoice.id)" v-if="[1,5].includes(item.Sale.salestype_id) && $can('update','Invoice') && item.Sale.Invoice.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="updateDebitNote(item.Sale.Debitnote.id)" v-if="[2].includes(item.Sale.salestype_id) && $can('update','DebitNote') && item.Sale.Debitnote.id != null"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateDebitNote') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="updateContractPayments(item.Sale.document_id)" v-if="[1,2,3,5].includes(item.Sale.salestype_id) && $can('update','Payment')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePayments') }}</v-list-item>
                                    <!---->

                                    <!--added 2022-05-18-->
                                    <v-list-item class="font-sm" @click="updateEpr(item.Sale.document_id)" v-if="['EPR'].includes(item.Sale.type) && $can('update','Epr')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateEpr') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="updateContract(item.Sale.Contract.id)" v-if="['EPR'].includes(item.Sale.type) && (item.Sale.Contract.id != null) && $can('update','Contract')"><v-icon color="black" small class="mr-1">edit</v-icon>{{ $t('message.updateContract') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="viewEprPdf(item)" v-if="['EPR'].includes(item.Sale.type) && $can('read','Epr')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.epr') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="viewSignedEprPoPdf(item)" v-if="['EPR'].includes(item.Sale.type) && $can('read','Epr') && item.Sale.SignedPurchaseOrder.location != null"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedPo') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="viewEprContractPdf(item)" v-if="['EPR'].includes(item.Sale.type) && $can('read','Contract')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.contract') }}</v-list-item>
                                    <!---->

                                    <!--added 2021-10-27-->
<!--                                    <v-list-item class="font-sm" @click="viewGrossMargin(item)" v-if="[1,5,99].includes(item.Sale.salestype_id) && $can('read','GrossMargin')"><v-icon color="black lighten-1" x-small class="mr-1">fas fa-funnel-dollar</v-icon>{{ $t('message.grossMargin') }}</v-list-item>-->

                                    <PrintGrossMarginButton
                                        :gross-margin-id="item.Sale.GrossMargin.id"
                                        :source="item.Sale.GrossMargin.source"
                                        :update-mode="true"
                                        :list-mode="true"
                                        v-if="item.Sale.GrossMargin.id != null && [1,5,99].includes(item.Sale.salestype_id) && $can('read','GrossMargin')"
                                    />

                                    <v-list-item class="font-sm" @click="viewPdf(item)" v-if="item.Sale.salestype_id == 2 && $can('read','Contract')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.contract') }}</v-list-item>

                                    <PrintContractButton
                                        :update-mode="true"
                                        :mergeable-docs="item.Sale.mergeableDocs"
                                        :list-mode="true"
                                        :contract-id="item.Sale.document_id"
                                        :contract-item="item"
                                        :sales-list="true"
                                        v-if="[1,5].includes(item.Sale.salestype_id) && $can('read','Contract')"
                                    />

                                    <v-list-item class="font-sm" @click="viewSignedPdf(item)" v-if="[1,5].includes(item.Sale.salestype_id) && (item.Sale.TmsSignedOrder.location != null || item.Sale.SignedOrder.location != null) && $can('read','Contract')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedContract') }}</v-list-item>

                                    <PrintPurchaseOrderButton
                                        :update-mode="true"
                                        :mergeable-docs="item.Sale.mergeablePoDocs"
                                        :list-mode="true"
                                        :purchase-order-id="item.Sale.Purchaseorder.id"
                                        :purchase-order-title="item.Sale.Purchaseorder.title"
                                        :self-list="false"
                                        v-if="[1,3,5,6].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && $can('read','Po')"
                                    />
                                    <PrintPurchaseOrderButton
                                        :update-mode="true"
                                        :list-mode="true"
                                        :purchase-order-id="item.Sale.Purchaseorder.id"
                                        :purchase-order-title="item.Sale.Purchaseorder.title"
                                        :self-list="false"
                                        v-if="[3].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && $can('read','WarehousePo') && $can('read','Po') == false"
                                    />
                                    <v-list-item class="font-sm" @click="viewSignedPoPdf(item)" v-if="[1,3,5,6].includes(item.Sale.salestype_id) && item.Sale.Purchaseorder.id != null && (item.Sale.TmsSignedPurchaseOrder.location != null || item.Sale.SignedPurchaseOrder.location != null) && $can('read','Po')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.signedPo') }}</v-list-item>

                                    <!--added 2021-08-13-->
                                    <v-list-item
                                        class="font-sm"
                                        @click="openNonShippingDocumentsDialog(item.Sale.document_id, (item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title))"
                                        v-if="['DS','CS','PO','SS','PO-EXP'].includes(item.Sale.type) && [1,2].includes(item.Sale.salestatus_id)"
                                    >
                                        <v-icon color="yellow darken-2" small class="mr-2">fas fa-folder-open</v-icon>
                                        {{ $t('message.otherContractDocuments') }}
                                    </v-list-item>
                                    <!---->

                                    <!--added 2021-08-27-->
                                    <v-list-item
                                        class="font-sm"
                                        @click="openShippingInfoAndDocumentsDialog(item.Sale.document_id, (item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title))"
                                        v-if="[1,2,3,5,6].includes(item.Sale.salestype_id) && [1,2].includes(item.Sale.salestatus_id) && $can('read','Shipment')"
                                    >
                                        <v-icon small color="blue lighten-1" class="mr-2">mdi-ferry</v-icon>
                                        {{ $t('message.shippingInfoAndDocuments') }}
                                    </v-list-item>
                                    <!---->

                                    <v-list-item class="font-sm" @click="updateSalesOrder(item.Sale.SalesOrder.id)" v-if="item.Sale.type == 'WH' && $can('update','SalesOrder')"><v-icon small class="mr-1">edit</v-icon>{{ $t('message.updateSalesOrder') }}</v-list-item>
                                    <v-list-item class="font-sm" @click="updateWhSalesInvoice(item.Sale.document_id)" v-if="item.Sale.type == 'WH' && $can('update','Invoice')"><v-icon small class="mr-1">edit</v-icon>{{ $t('message.updateInvoice') }}</v-list-item>
<!--                                    <v-list-item class="font-sm" @click="viewSoPdf(item)" v-if="item.Sale.type == 'WH' && $can('read','SalesOrder')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.salesOrder') }}</v-list-item>-->
<!--                                    <v-list-item class="font-sm" @click="viewWhSalesInvoicePdf(item)" v-if="item.Sale.type == 'WH' && $can('read','Invoice')"><v-icon small color="red lighten-1" class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.invoice') }}</v-list-item>-->

                                    <PrintSalesOrderButtons
                                        :sales-order-id="item.Sale.SalesOrder.id"
                                        :update-mode="true"
                                        :list-mode="true"
                                        :version="item.Sale.SalesOrder.item_list_version"
                                        v-if="item.Sale.type == 'WH' && $can('read','SalesOrder')"
                                    />
                                    <PrintLocalSaleButtons
                                        :local-sale-id="item.Sale.document_id"
                                        :office-id="item.Sale.SalesOrder.office_id"
                                        :update-mode="true"
                                        :list-mode="true"
                                        v-if="item.Sale.type == 'WH' && $can('read','LocalSale')"
                                    />
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
            :dialog.sync="dialogs.error"
            :alert_message.sync="dialogs.error_message"
            @dialog-closed="dialogClosed"
        />
        <ShippingInfoAndDocumentsV4
            :main-document="shippingInfoTitle"
            :open-dialog="shippingInfoAndDocumentsDialog"
            :shipping-documents="shippingDocuments"
            :eutr-documents="eutrDocuments"
            :shipment-id="shippingInfoId"
            @dialog-closed="closeShippingInfoAndDocumentsDialog()"
        />
        <NonShippingDocumentsList
            :main-document="nonShippingDocumentsTitle"
            :open-dialog="nonShippingDocumentsDialog"
            :non-shipping-documents="nonShippingDocuments"
            @dialog-closed="closeNonShippingDocumentsDialog()"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, formatDateShort, log, numberFormat} from "Helpers/helpers";
    import _ from 'lodash';
    import {api} from "Api/index";
    import highlight from 'vue-highlight-text/public/directive.min'
    // import ShippingDocumentsList from "Components/Appic/ShippingDocumentsList";
    import {v4 as uuidv4} from "uuid";
    import {mapFields} from "vuex-map-fields";
    // import SimpleAlert from "Components/Appic/SimpleAlert";
    // import ShippingInfoSummary from "Components/Appic/ShippingInfoSummary";

    const NonShippingDocumentsList = () => import("Components/Appic/NonShippingDocumentsList");
    const PrintContractButton = () => import("Components/Appic/Printing/PrintContractButton");
    const PrintGrossMarginButton = () => import("Components/Appic/Printing/PrintGrossMarginButton");
    const PrintLocalSaleButtons = () => import("Components/Appic/Printing/PrintLocalSaleButtons");
    const PrintPurchaseOrderButton = () => import("Components/Appic/Printing/PrintPurchaseOrderButton");
    const PrintSalesOrderButtons = () => import("Components/Appic/Printing/PrintSalesOrderButtons");
    const ShippingDocumentsList = () => import("Components/Appic/ShippingDocumentsList");
    // const ShippingInfoAndDocuments = () => import("Components/Appic/ShippingInfoAndDocuments");
    // const ShippingInfoAndDocumentsV2 = () => import("Components/Appic/ShippingInfoAndDocumentsV2");
    // const ShippingInfoAndDocumentsV3 = () => import("Components/Appic/ShippingInfoAndDocumentsV3");
    const ShippingInfoAndDocumentsV4 = () => import("Components/Appic/ShippingInfoAndDocumentsV4");
    const ShippingInfoSummary = () => import("Components/Appic/ShippingInfoSummary");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");

    export default {
        name: "SearchResults",
        components: {
            NonShippingDocumentsList,
            PrintContractButton,
            PrintGrossMarginButton,
            PrintLocalSaleButtons,
            PrintPurchaseOrderButton,
            PrintSalesOrderButtons,
            // ShippingInfoAndDocuments,
            // ShippingInfoAndDocumentsV2,
            // ShippingInfoAndDocumentsV3,
            ShippingInfoAndDocumentsV4,
            ShippingInfoSummary,
            SimpleAlert,
            ShippingDocumentsList
        },
        props: ['searchKeyword'],
        title: 'Search Results',
        data() {
            return {
                dialogs: {
                    error: false,
                    error_message: ""
                },
                eutrDocuments: [],
                hideDefaultFooter: false,
                highlightTextObject: {
                    keyword: this.searchKeyword,
                    sensitive: false,
                    overWriteStyle: {
                        color: 'black',
                        backgroundColor: 'yellow'
                    }
                },
                loader: true,
                loading: {
                    clear: false,
                    fetch: false,
                    items: false,
                    filterResults: false
                },
                nonShippingDocuments: [],
                nonShippingDocumentsDialog: false,
                nonShippingDocumentsTitle: '',
                Sales: [],
                scrollingList: false,
                searchTerm: null,
                shippingDocuments: [],
                shippingDocumentsDialog: false,
                shippingDocumentsTitle: '',
                shippingInfoAndDocumentsDialog: false,
                shippingInfoDialog: false,
                shippingInfoId: null,
                shippingInfoTitle: '',
                sorting: {},
                tableHeight: '100vh',
                tableOptions: {
                    page: 1,
                    itemsPerPage: 50
                },
                totalSales: 0,
                updatedFields: []
            }
        },
        directives: {
            highlight: highlight
        },
        computed: {
            ...mapGetters('document', {
                allDocuments: 'allDocuments',
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters([
                'currencies',
                'incoterms',
                'months',
                'uofmQty'
            ]),
            headers () {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Sale.document_id',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct'
                    },
                    {
                        id: 1,
                        text: this.$t('message.type'),
                        value: 'Sale.type',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 2,
                        text: this.$t('message.contractOrWhSale'),
                        value: 'Sale.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 3,
                        text: this.$t('message.status'),
                        value: 'Sale.salestatus_id',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct'
                    },
                    {
                        id: 4,
                        text: this.$t('message.date'),
                        value: 'Sale.salesdate',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 5,
                        text: this.$t('message.buyer'),
                        value: 'Sale.Customer.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 6,
                        text: this.$t('message.sc'),
                        value: 'Sale.SalesContact.abbreviation',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 7,
                        text: this.$t('message.spl'),
                        value: 'Sale.Supplier.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 8,
                        text: this.$t('message.supPiNo'),
                        value: 'Sale.supplier_pi_no',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 9,
                        text: this.$t('message.po'),
                        value: 'Sale.po',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 10,
                        text: this.$t('message.species'),
                        value: 'Sale.Species.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 11,
                        text: this.$t('message.grade'),
                        value: 'Sale.Grade.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 12,
                        text: this.$t('message.size'),
                        value: 'Sale.Size.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 13,
                        text: this.$t('message.container'),
                        value: 'Sale.containers',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 14,
                        text: this.$t('message.cost'),
                        value: 'Sale.cost',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-1 pl-1 font-sm text-right'
                    },
                    {
                        id: 15,
                        text: this.$t('message.value'),
                        value: 'Sale.value',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-1 pl-1 font-sm text-right'
                    },
                    {
                        id: 16,
                        text: this.$t('message.osd'),
                        value: 'Sale.shipweekdate',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 17,
                        text: this.$t('message.shipmentStatus'),
                        value: 'Sale.shipment_status',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 18,
                        text: this.$t('message.ets'),
                        value: 'Sale.etsdate',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 19,
                        text: this.$t('message.etd'),
                        value: 'Sale.etddate',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 20,
                        text: this.$t('message.eta'),
                        value: 'Sale.etadate',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 21,
                        text: this.$t('message.paymentBuyer'),
                        value: 'Sale.Paymentterm.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-7-pct'
                    },
                    {
                        id: 22,
                        text: this.$t('message.paymentSupplier'),
                        value: 'Sale.Supplierpayment.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-7-pct'
                    },
                    {
                        id: 23,
                        text: this.$t('message.invoice'),
                        value: 'Sale.Invoice.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 24,
                        text: this.$t('message.dn'),
                        value: 'Sale.Debitnote.title',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 25,
                        text: this.$t('message.remarks'),
                        value: 'Sale.internal_remarks',
                        searchable: true,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                    },
                    {
                        id: 26,
                        text: this.$t('message.margin'),
                        value: 'Sale.margin',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right'
                    },
                    {
                        id: 27,
                        text: this.$t('message.mgPct'),
                        value: 'Sale.margin_pct',
                        searchable: false,
                        sortable: false,
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-center'
                    }
                ]
                return headers
            },
            eutrFiles() {
                let files = [];
                files = this.allDocuments.ShippingEutrDocuments
                return files
            },
        },
        methods: {
            ...mapActions('document',{
                searchAssetDocument: 'searchAssetDocument'
            }),
            ...mapActions('report',{
                getAllSales: 'getAllSales'
            }),
            checkEutrEudrDocument(docTypeId) {
                const file = this.eutrFiles.find(ef => ef.Document.doctype_id == docTypeId)
                if(file) return true
                return false
            },
            closeNonShippingDocumentsDialog () {
                this.nonShippingDocumentsDialog = false
            },
            closeShippingDocumentsDialog () {
                this.shippingDocumentsDialog = false
            },
            closeShippingInfoDialog() {
                this.shippingInfoDialog = false
                this.shippingInfoId = null
                this.shippingInfoTitle = ''
            },
            closeShippingInfoAndDocumentsDialog() {
                this.shippingInfoAndDocumentsDialog = false
                this.shippingInfoId = null
                this.shippingInfoTitle = ''
                this.shippingDocuments = []
                this.eutrDocuments = []
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            formatDate,
            formatDateShort,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getStatus (contract) {
                let status = null
                if(contract.Sale.salestatus_id == 1 && [1,2,5].includes(contract.Sale.salestype_id) && (contract.Sale.signed == 0 || contract.Sale.signed == null)){
                    status = 'contractUnsigned'
                } else if(contract.Sale.salestatus_id == 1 && [1,2,5].includes(contract.Sale.salestype_id) && (contract.Sale.posigned == 0 || contract.Sale.posigned == null)) {
                    status = 'poUnsigned'
                } else if(contract.Sale.salestatus_id == 1 && [3].includes(contract.Sale.salestype_id) && (contract.Sale.posigned == 0 || contract.Sale.posigned == null)) {
                    status = 'whPoUnsigned'
                }
                return status

            },
            getUofMFormatPrecision (unit) {
                this.uofmQty.find(u => u.Measurement.title == unit).Measurement.formatPrecision
            },
            handleResize() {
                if(this.scrollingList) {
                    this.tableHeight = window.innerHeight - (130);
                } else {
                    this.tableHeight = null
                }
            },
            isUpdated(field) {
                if(this.updatedFields.includes(field)){
                    return ' red--text darken-3'
                }
                return ''
            },
            loadSearchResults () {
                this.loading.items = true
                this.loading.fetch = true

                return new Promise((resolve, reject) => {
                    api
                        .get("/search", {
                            params: {
                                searchKeyword: this.searchKeyword
                            }
                        })
                        .then(response => {
                            this.Sales = response.data.data,
                                this.totalSales = response.data.totalRows
                            this.loading.items = false
                            this.loading.fetch = false
                            resolve('ok')
                        })
                        .catch(error => {
                            reject(error);
                            this.loading.items = false
                            this.loading.fetch = false
                        })
                        .finally(() => {
                            this.tableOptions.page = 1
                        })
                })
            },
            openNonShippingDocumentsDialog (documentId, documentTitle) {
                this.loading.items = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/other-contract-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.nonShippingDocuments = []
                        this.nonShippingDocumentsTitle = ''
                        this.nonShippingDocuments = data
                        this.nonShippingDocumentsTitle = documentTitle
                    })
                    .catch(() => {
                        this.loading.items = false;
                    })
                    .then(() => {
                        let self = this
                        setTimeout(function(){
                            self.loading.items = false;
                            self.nonShippingDocumentsDialog = true;
                        }, 100)
                    })
            },
            openShippingDocumentsDialog ( documentId, documentTitle ) {
                this.loading.items = true
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/shipping-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.shippingDocuments = []
                        this.shippingDocumentsTitle = ''
                        this.shippingDocuments = data
                        this.shippingDocumentsTitle = documentTitle
                    })
                    .catch(() => {
                        this.loading.items = false;
                    })
                    .then(() => {
                        let self = this
                        setTimeout(function(){
                            self.loading.items = false;
                            self.shippingDocumentsDialog = true;
                        }, 100)
                    })
            },
            openShippingInfoDialog( documentId, documentTitle ) {
                this.shippingInfoTitle = documentTitle
                this.shippingInfoId = documentId
                this.shippingInfoDialog = true
            },
            openShippingInfoAndDocumentsDialog(documentId, documentTitle) {
                this.loading.items = true
                this.shippingDocuments = []
                this.eutrDocuments = []
                let promise = new Promise((resolve, reject) => {
                    api
                        .get("/shipping-documents/" + documentId)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
                promise
                    .then( (data) => {
                        this.loading.items = false;
                        data.forEach( d => {
                            if(this.checkEutrEudrDocument(d.Document.doctype_id)){
                                this.eutrDocuments.push(d)
                            } else {
                                this.shippingDocuments.push(d)
                            }
                        })
                        this.shippingInfoTitle = documentTitle
                        this.shippingInfoId = documentId
                        this.shippingInfoAndDocumentsDialog = true
                    })
                    .catch(() => {
                        this.loading.items = false;
                    })
            },
            styleTheRow (item) {
                let rowClass = null
                switch( item.Sale.rowClass ){
                    case 'first-row':
                        rowClass = 'border-top'
                        break
                    case 'last-row':
                        rowClass = 'border-bottom'
                        break
                    case 'single-row':
                        rowClass = 'border-top-and-bottom-and-left-and-right'
                        break
                    default:
                        rowClass = 'border-left-and-right'
                        break
                }
                return rowClass
            },
            updateContract (val) {
                let tabName = 'contract_' + val
                if(this.openedTabs.includes(tabName)){
                    window.open('/v1/contracts/update/' + val, tabName)
                    // tabName.focus()
                } else {
                    let tab = window.open('/v1/contracts/update/' + val, '_blank')
                    tab.name = tabName
                    this.openedTabs.push(tab.name)
                }
            },
            updateContractPayments(val) {
                let tab = window.open('/v1/payments/update/contracts/' + val,'_blank')
                tab.name = 'contractPayments_' + val
                this.openedTabs.push(tab.name)
            },
            updateDebitNote(val) {
                let tab = window.open('/v1/debitnotes/update/' + val,'_blank')
                tab.name = 'debitNote_' + val
                this.openedTabs.push(tab.name)
            },
            updateEpr(val) {
                let tab = window.open('/v1/eprs/update/' + val,'_blank')
                tab.name = 'epr_' + val
                this.openedTabs.push(tab.name)
            },
            updateInvoice(val) {
                let tab = window.open('/v1/invoices/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateLca(val) {
                let tab = window.open('/v1/lcas/update/' + val,'_blank')
                tab.name = 'lca_' + val
                this.openedTabs.push(tab.name)
            },
            updatePackingList(val) {
                let tab = window.open('/v1/packinglists/update/' + val,'_blank')
                tab.name = 'packingList_' + val
                this.openedTabs.push(tab.name)
            },
            updatePurchaseOrder(val) {
                let tab = window.open('/v1/purchaseorders/update/' + val,'_blank')
                tab.name = 'purchaseOrder_' + val
                this.openedTabs.push(tab.name)
            },
            updateSalesOrder (val) {
                let tab = window.open('/v1/salesorders/update/' + val,'_blank')
                tab.name = 'salesOrder_' + val
                this.openedTabs.push(tab.name)
            },
            updateWhSalesInvoice (val) {
                let tab = window.open('/v1/localsales/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShippingInstruction(val){
                let tab = window.open('/v1/sdas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            updateShipment (val) {
                let tabName = 'shipment_' + val
                if(this.openedTabs.includes(tabName)){
                    window.open('/v1/shipments/update/' + val, tabName)
                } else {
                    let tab = window.open('/v1/shipments/update/' + val, '_blank')
                    tab.name = tabName
                    this.openedTabs.push(tab.name)
                }
                // let tab = window.open('/v1/shipments/update/' + val,'_blank')
                // tab.name = 'shipment_' + val
                // this.openedTabs.push(tab.name)
            },
            viewGrossMargin(item){
                //check if gross margin calculation exists
                if( item.Sale.GrossMargin.id == null ) {
                    this.dialogs.error_message = this.$t('message.grossMarginNotAvailableYet')
                    this.dialogs.error = true
                } else {
                    let title = ''
                    let source = 'invoice'
                    if(item.Sale.salestype_id != 99){
                        title = item.Sale.document_version != null ? [item.Sale.Contract.title, item.Sale.Contract.revision_no , item.Sale.Contract.partial_no].filter(Boolean).join('-') : item.Sale.Contract.title
                    } else {
                        title = item.Sale.So.title
                        source = 'whsales_invoice'
                    }
                    let document = encodeURIComponent('GM ' + title)
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + document
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/gross-margins/print/'
                        + source
                        + '/'
                        + item.Sale.GrossMargin.id
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                        , "_blank");
                }
            },
            viewEprPdf (epr) {
                let document = epr.Sale.title
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/eprs/print/'
                    + contract.Sale.document_id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewEprContractPdf (contract) {
                let document = contract.Contract.title
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/contracts/print/'
                    + contract.Sale.document_id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewSignedEprPoPdf(epr) {
                let tab = window.open(epr.Sale.SignedPurchaseOrder.location,"_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdf (contract) {
                let document = contract.Sale.document_version != null ? [contract.Sale.Contract.title, contract.Sale.Contract.revision_no , contract.Sale.Contract.partial_no].filter(Boolean).join('-') : contract.Sale.Contract.title
                if([1,5].includes(contract.Sale.salestype_id)) {
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + encodeURIComponent(document)
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/contracts/print/'
                        + contract.Sale.document_id
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                        , "_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else if(contract.Sale.salestype_id == 2){
                    if(contract.Sale.document_version != null){
                        if (contract.Sale.SignedOrder.location != null) {
                            let tab = window.open(contract.Sale.SignedOrder.location, "_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            if(contract.Sale.TmsSignedOrder.location != null){
                                let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                                this.openedTabs.push(tab.name)
                            } else {
                                this.dialogs.error = true
                                this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                            }
                        }
                    } else {
                        if(contract.Sale.TmsSignedOrder.location != null){
                            let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.dialogs.error = true
                            this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                        }
                    }
                } else {
                    return false
                }
            },
            viewSignedPdf (contract) {
                if(contract.Sale.document_version != null && contract.Sale.SignedOrder.location != null){
                    let tab = window.open(contract.Sale.SignedOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else {
                    let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            },
            viewSoPdf (salesorder) {
                let document = 'SO ' + salesorder.Sale.title
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/salesorders/print/'
                    + salesorder.Sale.SalesOrder.id
                    + '/so/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPoPdf ( contract ) {
                let document = contract.Sale.Purchaseorder.title + '_PO'
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/purchaseorders/print/'
                    + contract.Sale.Purchaseorder.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewWhSalesInvoicePdf (invoice) {
                let document = 'INV ' + invoice.Sale.title
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/localsales/print/'
                    + invoice.Sale.document_id
                    + '/si/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewSignedPoPdf (contract) {
                if(contract.Sale.document_version != null && contract.Sale.SignedPurchaseOrder.location != null){
                    let tab = window.open(contract.Sale.SignedPurchaseOrder.location,"_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                } else {
                    let tab = window.open(contract.Sale.TmsSignedPurchaseOrder.location, "_blank")
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            }
        },
        watch: {
            scrollingList() {
                this.handleResize()
            }
        },
        created(){
            if(this.searchKeyword != null && this.searchKeyword.trim().length > 0) {
                this.loadSearchResults()
            }
        },
        mounted(){
            this.loader = false;
            this.handleResize()
            if(this.$route.query['updates[]']) {
                this.updatedFields = this.$route.query['updates[]']
            }
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>

<style>
/*.border-top td {*/
/*    border-top: #3C6932 3px solid;*/
/*}*/
/*.border-top td:first-child {*/
/*    border-left: #3C6932 3px solid;*/
/*}*/
/*.border-top td:last-child {*/
/*    border-right: #3C6932 3px solid;*/
/*}*/
/*.border-bottom td {*/
/*    border-bottom: #3C6932 3px solid !important;*/
/*}*/
/*.border-bottom td:first-child {*/
/*    border-left: #3C6932 3px solid;*/
/*}*/
/*.border-bottom td:last-child {*/
/*    border-right: #3C6932 3px solid;*/
/*}*/
/*.border-left-and-right td:first-child {*/
/*    border-left: #3C6932 3px solid;*/
/*}*/
/*.border-left-and-right td:last-child {*/
/*    border-right: #3C6932 3px solid;*/
/*}*/
/*.border-top-and-bottom-and-left-and-right td {*/
/*    border-top: #3C6932 3px solid;*/
/*    border-bottom: #3C6932 3px solid !important;*/
/*}*/
/*.border-top-and-bottom-and-left-and-right td:first-child {*/
/*    border-left: #3C6932 3px solid;*/
/*}*/
/*.border-top-and-bottom-and-left-and-right td:last-child {*/
/*    border-right: #3C6932 3px solid;*/
/*}*/
.font-xs {
    font-size: 0.60rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}

.v-data-table tbody td {
    vertical-align: middle !important;
}

v-input--selection-controls__input {
    margin-right: 0px !important;
}
.v-radio.theme--light {
    margin-right: 7px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.v-radio.theme--light label{
    font-size: 0.8rem !important;
}
</style>